




























































import { Component, Vue } from "vue-property-decorator";
import utils from "@/libs/utils";

@Component
export default class Roles extends Vue {
  pagerHeader: any = {
    title: "角色管理",
    desc: "每个角色具有不同的权限，一个用户可以拥有多个角色。",
    breadcrumb: ["系统", "角色管理"]
  };
  dialogFormVisible: boolean = false;
  activeName: string = "1";
  editModel: any = {
    displayName: null,
    description: null,
    isStatic: false,
    isDefault: false,
    grantedPermissions: [],
    id: 0
  };
  rules: any = {
    /*name: [
                        {required: true, message: '请输入角色代码', trigger: 'blur'},
                    ],*/
    displayName: [
      { required: true, message: "请输入角色名称", trigger: "blur" }
    ],
    grantedPermissions: [
      { required: true, message: "请配置权限", trigger: "change" },
      {
        validator: (rule: any, value: string, callback: Function) => {
          console.log(value);
          if (!value || value.length === 0) {
            callback(new Error("请配置权限"));
          } else {
            callback();
          }
        },
        trigger: "change"
      }
    ]
  };
  loading: boolean = false;
  editLoading: boolean = false;
  permissionsTree: any[] = [];
  tableData: any[] = [];

  activated() {
    this.getPermissions();
    this.getData();
  }
  created() {
    this.getPermissions();
    this.getData();
  }

  async getData() {
    this.loading = true;
    try {
      let res = await this.$ajax.get("/api/services/app/Role/GetAll");

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  // 分页结束
  async getPermissions() {
    try {
      let res = await this.$ajax.get(
        "/api/services/app/Role/GetAllPermissions"
      );

      if (res.data.success) {
        this.permissionsTree = res.data.result.items;
      }
    } catch (error) {
      console.log(error);
    }
  }

  openDialog() {
    this.resetForm();
    this.activeName = "1";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.permissionTree as any).setCheckedKeys([]);
    });
  }
  checkChange() {
    this.editModel.grantedPermissions = (this.$refs
      .permissionTree as any).getCheckedKeys();
  }
  async handleEdit(index: number, row: any) {
    this.loading = true;
    try {
      const res = await this.$ajax.get("/api/services/app/Role/Get", {
        params: { id: row.id }
      });
      if (res.data.success) {
        this.editModel.id = res.data.result.id;
        this.editModel.displayName = res.data.result.displayName;
        this.editModel.description = res.data.result.description;
        this.editModel.isStatic = res.data.result.isStatic;
        this.editModel.isDefault = res.data.result.isDefault;
        this.editModel.grantedPermissions = res.data.result.grantedPermissions;
        this.dialogFormVisible = true;
        this.activeName = "1";
        this.$nextTick(() => {
          (this.$refs.permissionTree as any).setCheckedKeys(
            this.editModel.grantedPermissions
          );
        });
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  async _delete(ids: any[]) {
    let res = await this.$confirm("此操作将删除数据, 是否继续?", "", {
      confirmButtonText: "是",
      cancelButtonText: "取消",
      type: "warning"
    });

    if (res == "confirm") {
      this.loading = true;
      try {
        let res = await this.$ajax.delete(
          "/api/services/app/Role/Delete" + "?id=" + ids.join("&ids=")
        );

        this.loading = false;
        if (res.data.success) {
          this.getData();
          this.$notify({
            title: "删除成功",
            message: "",
            type: "success"
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }
  }
  // 分页开始
  handleSizeChange(val: number) {
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.getData();
  }
  resetForm() {
    this.editModel = {
      displayName: null,
      description: null,
      isStatic: false,
      isDefault: false,
      grantedPermissions: [],
      id: 0
    };
  }
  submitForm(formName: string) {
    (this.$refs.editForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.editLoading = true;
        if (this.editModel.id === 0) {
          try {
            let res = await this.$ajax.post(
              "/api/services/app/Role/Create",
              this.editModel
            );

            if (res.data.success) {
              this.dialogFormVisible = false;
              this.$notify({
                title: "成功",
                message: "角色添加成功",
                type: "success"
              });
              this.resetForm();
              this.getData();
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            let res = await this.$ajax.put(
              "/api/services/app/Role/Update",
              this.editModel
            );

            if (res.data.success) {
              this.dialogFormVisible = false;
              this.$notify({
                title: "成功",
                message: "角色修改成功",
                type: "success"
              });
              this.resetForm();
              this.getData();
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
      this.editLoading = false;
    });
  }
}
